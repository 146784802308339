
import { OrganizationTags } from "@/mixins/http/OrganizationMixin";
import { Component, Watch, Prop, Mixins } from "vue-property-decorator";
import RealmSelect from "@/components/Realm/Select.vue";
import UserSelect from "@/components/User/Select.vue";
import OrganizationMixin from "@/mixins/http/OrganizationMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import WithErrors from "@/directives/WithErrors";
import { OrganizationPayload } from "../../models/Organization";
import Throttler from "@/utils/Throttler";
import Realm from "../../models/Realm";
import { realmCodeFromOrigin } from "@/utils/Realms";

@Component({
  directives: {
    WithErrors,
  },
  components: {
    RealmSelect,
    UserSelect,
  },
})
export default class OrganizationForm extends Mixins(OrganizationMixin) {
  @Prop({ required: true }) readonly value!: OrganizationPayload;

  private item: OrganizationPayload = this.value;

  private ipaData: any = null;
  private throttler: Throttler = new Throttler(1000);
  private suggestions: any[] = [];
  get httpTag() {
    return this.item.id
      ? OrganizationTags.OrganizationUpdate
      : OrganizationTags.OrganizationCreate;
  }

  private loadingIpaData = false;

  @Watch("item", { deep: true })
  onFieldsChanged() {
    this.$emit("input", this.item);
  }

  get isUpdating() {
    return !!this.item.id;
  }

  get disabled() {
    return !this.item.code;
  }

  get showForm() {
    return this.ipaData || this.isUpdating;
  }

  @Watch("item.code", { deep: true })
  onCodeChanged(value: string, oldValue: string | undefined) {
    if (!value || this.loadingIpaData || !oldValue) {
      return;
    }
    this.throttler.run(() => {
      this.getDataFromIpa(value);
    });
  }

  async onSuggestionClick(suggestion: any) {
    this.suggestions = [];
    await this.getDataFromIpa(suggestion.codEnte);
  }

  @Watch("item.name", { deep: true })
  onNameChanged(value: string, oldValue: string | undefined) {
    if (!value || this.loadingIpaData || !oldValue) {
      return;
    }
    this.throttler.run(() => {
      this.searchOrganizationsFromIpa({ term: value });
    });
  }

  get realmCode() {
    return realmCodeFromOrigin();
  }

  onItemsLoaded(items: Realm[]) {
    this.preloadRealmIdIfMissing(items);
  }

  private preloadRealmIdIfMissing(realms: Realm[]) {
    if (this.item.realmId) {
      return;
    }

    let realmId = realms.find((o: Realm) => o.code === this.realmCode)?.id;
    if (this.$route.name == "realms_show") {
      realmId = this.$route.params.id.toString();
    }

    this.item.realmId = realmId;
  }

  async searchOrganizationsFromIpa(payload: { code?: string; term?: string }) {
    this.loadingIpaData = true;
    const [data, errors] = await safeAsync(
      this.searchIpaOrganizations(payload)
    );

    if (data) {
      if (data.length === 1 && data[0]?.codEnte) {
        this.getDataFromIpa(data[0]?.codEnte);
      } else {
        this.suggestions = data;
      }
    }

    this.loadingIpaData = false;
  }

  async getDataFromIpa(code: string) {
    this.loadingIpaData = true;
    const [data, errors] = await safeAsync(this.getIpaData(code));
    if (data) {
      this.ipaData = data;
      this.item.fromIpaData(this.ipaData);
    }
    await this.$nextTick;
    this.loadingIpaData = false;
  }
}
