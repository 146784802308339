
import { Component, Ref, Mixins } from "vue-property-decorator";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import OrganizationMixin, {
  OrganizationTags,
} from "@/mixins/http/OrganizationMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast, showToast } from "@/utils/Toast";
import { Organization } from "../../models";
import OrganizationForm from "./Form.vue";
import { httpStore } from "../../store/typed";
import { OrganizationPayload } from "../../models/Organization";

@Component({
  components: {
    VModal,
    OrganizationForm,
  },
})
export default class OrganizationModal extends Mixins(OrganizationMixin) {
  @Ref() readonly modal!: VModal;

  private organization: Organization | null = null;

  private organizationPayload: OrganizationPayload | null = null;

  get actionName() {
    return this.isUpdating ? "Aggiorna" : "Crea";
  }

  private onSubmit() {
    if (this.organization?.id) {
      this.update();
    } else {
      this.create();
    }
  }

  get isUpdating() {
    return this.organization?.id;
  }

  get loading() {
    return (
      httpStore.status[OrganizationTags.OrganizationCreate]?.loading ||
      httpStore.status[OrganizationTags.OrganizationUpdate]?.loading
    );
  }

  private async create() {
    const [data, errors] = await safeAsync(
      this.createOrganization(this.organizationPayload)
    );

    if (errors) {
      showErrorToast("Errore durante la creazione della PA");
      return;
    }

    showToast("Creazione avvenuta con successo!");
    this.$emit("created", data);
    this.hide();
  }

  private async destroy() {
    const [data, errors] = await safeAsync(
      this.destroyOrganization(this.organization.id)
    );

    if (errors) {
      showErrorToast("Errore durante la cancellazione della PA");
      return;
    }

    this.$emit("destroyed", data.id);
    this.hide();
  }

  reset() {
    this.organization = null;
    this.organizationPayload = null;
  }

  private async update() {
    const [data, errors] = await safeAsync(
      this.updateOrganization(this.organizationPayload)
    );

    if (errors) {
      showErrorToast("Errore durante la modifica della PA");
      return;
    }

    showToast("Aggiornamento avvenuto con successo!");
    this.$emit("updated", data);
    this.hide();
  }

  add(organization: Organization) {
    this.assignObject(organization);
    this.modal.show();
  }

  assignObject(organization: Organization) {
    this.organization = organization;
    if (this.organization) {
      this.organizationPayload = new OrganizationPayload(organization);
    }
  }

  edit(organization: Organization) {
    this.assignObject(organization);
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }
}
