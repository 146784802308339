
import { Component, Mixins, Prop, Watch, Ref } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import { Organization } from "../../models";
import VThrottlerInput from "@/components/Common/VThrottlerInput.vue";
import OrganizationMixin, {
  OrganizationTags,
} from "@/mixins/http/OrganizationMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import { currentUserStore } from "@/store/typed";
import VPaginator from "@/components/Common/VPaginator.vue";
import RequestData from "@/store/types/RequestData";
import EmptyView from "@/components/Common/EmptyView.vue";
import OrganizationModal from "@/components/Organization/Modal.vue";
@Component({
  components: {
    PageSection,
    EmptyView,
    VPaginator,
    OrganizationModal,
    VThrottlerInput,
  },
})
export default class OrganizationIndex extends Mixins(OrganizationMixin) {
  @RegisterHttp(OrganizationTags.OrganizationIndex)
  readonly indexRequest!: RequestData;

  @Prop() readonly realmId: any;

  @Ref() readonly organizationModal!: OrganizationModal;

  private items: Organization[] = [];

  private error = false;

  private term: string | null = null;

  private page = 0;

  get columns() {
    return [
      { name: "name", label: "Nome" },
      { name: "actions", label: "" },
    ];
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  onCreated() {
    this.onPageChanged(1);
  }

  onUpdated(organization: Organization) {
    const index = this.items.findIndex(
      (o: Organization) => o.id === organization.id
    );
    this.$set(this.items, index, organization);
  }

  onDestroyed(id: string) {
    this.items = this.items.filter((o: Organization) => o.id !== id);
  }

  onPageChanged(page: number) {
    this.page = page;
    this.load();
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  @Watch("term")
  onTermChanged(term: string) {
    this.$nextTick(() => {
      this.load();
    });
  }

  mounted() {
    this.load();
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  get meta() {
    return this.indexRequest?.meta;
  }

  get filter() {
    const data: any = {
      page: { number: this.page },
      filter: { term: this.term },
    };

    if (this.realmId) {
      data.filter.realm = this.realmId;
    }

    return data;
  }

  get defaultOrganization() {
    const instance = new Organization();
    instance.realmId = this.realmId;
    return instance;
  }

  openCreateModal() {
    (this.organizationModal as any).add(this.defaultOrganization);
  }

  openEditModal(organization: Organization) {
    (this.organizationModal as any).edit(organization);
  }

  goToDetail(organization: Organization) {
    this.$router.push({
      name: "organizations_show",
      params: { organizationId: organization.id },
    });
  }

  async load() {
    const [data, errors] = await safeAsync(
      this.getOrganizations(this.filter.page, this.filter.filter)
    );

    if (!errors) {
      this.items = data;
    } else {
      this.error = true;
    }
  }
}
