
import { Component, Mixins } from "vue-property-decorator";
import OrganizationMixin, {
  OrganizationTags,
} from "@/mixins/http/OrganizationMixin";
import PageSection from "@/components/Common/PageSection.vue";
import { safeAsync } from "@/utils/AsyncUtil";
import RequestData from "@/store/types/RequestData";
import UsersList from "@/components/User/List.vue";
import { RegisterHttp } from "@/utils/Decorators";
import OrganizationsList from "@/components/Organization/List.vue";
import Organization from "@/models/Organization";

@Component({
  components: {
    OrganizationsList,
    PageSection,
    UsersList,
  },
})
export default class OrganizationShow extends Mixins(OrganizationMixin) {
  @RegisterHttp(OrganizationTags.OrganizationIndex)
  readonly showRequest!: RequestData;

  private item: Organization = null;
  private error = false;

  mounted() {
    this.load();
  }

  get filter() {
    return { organization: this.item.id };
  }

  get id() {
    return this.$route.params.organizationId;
  }

  get loading() {
    return this.showRequest?.loading;
  }

  get meta() {
    return this.showRequest?.meta;
  }

  async load() {
    const [data, errors] = await safeAsync(this.getOrganization(this.id));
    if (!errors) {
      this.item = data;
    } else {
      this.error = true;
    }
  }
}
