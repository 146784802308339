
import { Component, Vue } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import EmptyView from "@/components/Common/EmptyView.vue";
import OrganizationsList from "@/components/Organization/List.vue";

@Component({
  components: {
    PageSection,
    OrganizationsList,
    EmptyView,
  },
})
export default class OrganizationIndex extends Vue {}
